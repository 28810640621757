<template>
    <div class="contenedor-cliente">
        <div class="space-header"></div>          
        <TabsButton 
            :arraySessions="['Agregar Cliente','Exportar']"
            :firstSelect="false"
            :vModel='(data) => tabFormsMain = data'
            :bulReset='bulResetTabFormsMain'
            :vResetBul='() => bulResetTabFormsMain = false'
            type="sessions"
        />
        <FormCustom
            typeContainer="window"
            title="Agregar Cliente"
            textSecButton="Agregar"
            :actionSecButton="(data)=>agregarCliente(data, true)"
            widthWindowForm="400px"
            :exitForm='() => bulResetTabFormsMain = true'
            :bulWindow="tabFormsMain['Agregar Cliente']"
            :limitInputsInSection="4"
            :objectForm="[
                {
                    type: 'text',
                    label: 'Nombre',
                    name: 'Nombre_cliente',
                    
                },
                {
                    type: 'text',
                    label: 'Teléfonos',
                    name: 'Telefonos',
                    
                },
                {
                    type: 'text',
                    label: 'Contribuyente',
                    name: 'Contribuyente',
                    
                },
                {
                    type: 'text',
                    label: 'Observaciones',
                    name: 'Observaciones',                    
                    
                },
                {
                    type: 'text',
                    label: 'Cuenta Domiciliaria',
                    name: 'Cuenta_domiciliaria',
                    
                },
                {
                    type: 'text',
                    label: 'Contacto',
                    name: 'Contacto',
                    
                },
                {
                    type: 'text',
                    label: 'Cobrador',
                    name: 'Cobrador',
                    
                },
                {
                    type: 'text',
                    label: 'RIF',
                    name: 'Rif',
                    
                },
                {
                    type: 'text',
                    label: 'Dirección',
                    name: 'Dirrecion',
                    
                },
                {
                    type: 'text',
                    label: 'Límite De Crédito',
                    name: 'Limite_de_credito',
                    
                },
                {
                    type: 'number',
                    label: 'Descuento %',
                    name: 'Descuento',
                    
                },
                {
                    type: 'number',
                    label: 'Retención %',
                    name: 'Retencion',
                    
                },
                {
                    type: 'number',
                    label: 'Tarifa',
                    name: 'Tarifa',
                    
                },
                {
                    type: 'text',
                    label: 'Banco Domiciliario',
                    name: 'Banco_domiciliario',
                    
                },
                {
                    type: 'text',
                    label: 'Email',
                    name: 'Email',
                    
                },
                {
                    type: 'text',
                    label: 'Zona',
                    name: 'Zona',
                    
                },
                {
                    type: 'text',
                    label: 'Vendedor',
                    name: 'Vendedor',
                    
                },
            ]"
        />
        <div class="contenedor-caja-historial">
            <TabsButton
                :arraySessions="['Clientes','Vendedores','Cobradores']"
                :firstSelect="true"
                :vModel='(data) => tabTablas = data'
                type="sessionsCards"
            />
            <Table 
                :heightTable=400
                :dataSourceFilter="clientesFiltros"
                :actionEdit="(data) => {
                    memoriaCliente = data
                    bulEditaCliente = true
                }"
                :actionDelete="(data) =>{
                    memoriaCliente = data
                    bulQuestionDelete = true
                }"
                :boolVisible="tabTablas['Clientes']"
                :stateData="{
                    data: 'clientes', 
                    dataFilterSet: 'SET_CLIENTES_FILTROS', 
                }"
                :arrayNameColumns="[
                    {name:'Referencias', reference: 'Nombre_cliente', type: 'text', width: 200},
                    {name:'Montos', reference: 'Cantidad', type: 'number', width: 200},
                    {name:'Tipo', reference: 'Tipo_de_credito', type: 'text', width: 200},
                    {name:'Nombre de los clientes', reference: 'Nombre_cliente', type: 'text', width: 200},
                    {name:'Fechas', reference: 'createdAt', type: 'date', width: 200},
                    {name:'Codigos contables', reference: 'Cc_caja', type: 'text', width: 200},
                    {name:'Concepto', reference: 'Concepto', type: 'text', width: 200},
                ]"
            />
            <Table 
                :heightTable=400
                :dataSourceFilter="cajasFiltros"
                :actionEdit="(data) => {
                    bulEditaCaja = true
                    memoriaCaja = {...data}
                    nuevoNombreCaja = data.Nombre_caja
                }"
                :actionDelete="(data) => {eliminarCaja(data)}"
                :boolVisible="tabTablas['Vendedores']"
                :stateData="{
                    data: 'cajas', 
                    dataFilterSet: 'SET_CAJAS_FILTROS', 
                }"
                :arrayNameColumns="[
                    {name:'Nombre', reference: 'Nombre_caja', type: 'text', width: 200},
                    {name:'Montos', reference: 'Cc_caja', functionData: saldoDeLaCaja, type: 'number', width: 200},
                    {name:'Codigos contables', reference: 'Cc_caja' , type: 'text', width: 200},
                ]"
            />
            <Table 
                :heightTable=400
                :dataSourceFilter="cajasFiltros"
                :actionEdit="(data) => {
                    bulEditaCaja = true
                    memoriaCaja = {...data}
                    nuevoNombreCaja = data.Nombre_caja
                }"
                :actionDelete="(data) => {eliminarCaja(data)}"
                :boolVisible="tabTablas['Cobradores']"
                :stateData="{
                    data: 'cajas', 
                    dataFilterSet: 'SET_CAJAS_FILTROS', 
                }"
                :arrayNameColumns="[
                    {name:'Nombre', reference: 'Nombre_caja', type: 'text', width: 200},
                    {name:'Montos', reference: 'Cc_caja', functionData: saldoDeLaCaja, type: 'number', width: 200},
                    {name:'Codigos contables', reference: 'Cc_caja' , type: 'text', width: 200},
                ]"
            />            
        </div>
        <ModalCustom
            :bulModal="bulQuestionDelete"
            :exitModal='() => bulQuestionDelete = false'
            widthCard="600px"
            title="¿Seguro que quieres eliminar este cliente?"
        >
            <div class="contenedor-seguro-boton2 d-flex flex-column flex-sm-row justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="()=>{
                    eliminarCliente(memoriaCliente)
                    bulQuestionDelete = false
                }">
                    Eliminar
                </button>
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="bulQuestionDelete = false" >Cancelar</button>
            </div>
        </ModalCustom>
        <ModalCustom
            :bulModal="bulEditaCliente"
            :exitModal='() => bulEditaCliente = false'
            :widthCard="'100%'"
        >
            <FormCustom
                textSecButton="Editar"
                typeStyle="column"
                :isOneButton="true"
                :objectForm="[
                    {
                        type: 'text',
                        label: 'Nombre',
                        name: 'Nombre_cliente',
                        valueDefault: memoriaCliente.Nombre_cliente
                    },
                    {
                        type: 'text',
                        label: 'Teléfonos',
                        name: 'Telefonos',
                        valueDefault: memoriaCliente.Telefonos
                    },
                    {
                        type: 'text',
                        label: 'Contribuyente',
                        name: 'Contribuyente',
                        valueDefault: memoriaCliente.Contribuyente
                    },
                    {
                        type: 'text',
                        label: 'Observaciones',
                        name: 'Observaciones',                    
                        valueDefault: memoriaCliente.Observaciones
                    },
                    {
                        type: 'text',
                        label: 'Cuenta Domiciliaria',
                        name: 'Cuenta_domiciliaria',
                        valueDefault: memoriaCliente.Cuenta_domiciliaria
                    },
                    {
                        type: 'text',
                        label: 'Contacto',
                        name: 'Contacto',
                        valueDefault: memoriaCliente.Contacto
                    },
                    {
                        type: 'text',
                        label: 'Cobrador',
                        name: 'Cobrador',
                        valueDefault: memoriaCliente.Cobrador
                    },
                    {
                        type: 'text',
                        label: 'RIF',
                        name: 'Rif',
                        valueDefault: memoriaCliente.Rif
                    },
                    {
                        type: 'text',
                        label: 'Dirección',
                        name: 'Dirrecion',
                        valueDefault: memoriaCliente.Dirrecion
                    },
                    {
                        type: 'text',
                        label: 'Límite De Crédito',
                        name: 'Limite_de_credito',
                        valueDefault: memoriaCliente.Limite_de_credito
                    },
                    {
                        type: 'number',
                        label: 'Descuento %',
                        name: 'Descuento',
                        valueDefault: memoriaCliente.Descuento
                    },
                    {
                        type: 'number',
                        label: 'Retención %',
                        name: 'Retencion',
                        valueDefault: memoriaCliente.Retencion
                    },
                    {
                        type: 'number',
                        label: 'Tarifa',
                        name: 'Tarifa',
                        valueDefault: memoriaCliente.Tarifa
                    },
                    {
                        type: 'text',
                        label: 'Banco Domiciliario',
                        name: 'Banco_domiciliario',
                        valueDefault: memoriaCliente.Banco_domiciliario
                    },
                    {
                        type: 'text',
                        label: 'Email',
                        name: 'Email',
                        valueDefault: memoriaCliente.Email
                    },
                    {
                        type: 'text',
                        label: 'Zona',
                        name: 'Zona',
                        valueDefault: memoriaCliente.Zona
                    },
                    {
                        type: 'text',
                        label: 'Vendedor',
                        name: 'Vendedor',
                        valueDefault: memoriaCliente.Vendedor
                    },
                ]"
                :buttons="[,
                    {
                        text: 'Editar',
                        style: {
                            marginTop: '-40px',
                            width: '150px',
                        }
                    }
                ]"
                :actionSecButton="(data)=>{
                    editarCliente({...memoriaCliente, ...data})
                    bulEditaCliente = false
                }"
            />
        </ModalCustom>          
    </div>
</template>

<script>
    import Footer from "../../../components/Footer.vue"
    import {mapState, mapActions} from "vuex"
    import FormCustom from "../../../components/FormCustom.vue"
    import Table from "../../../components/Table.vue"
    import TabsButton from "../../../components/TabsButton.vue"
    import ModalCustom from "../../../components/ModalComponent.vue"
    import FilterExport from '../../../components/FilterExport.vue'    

    export default {
        name: "App",
        components: {
            TabsButton,
            Table,
            ModalCustom,
            FormCustom,
            Footer,
            FilterExport,
        },
        data(){
            return{
                //tabs
                tabFormsMain: {},
                tabTablas: {},
                bulResetTabFormsMain: false,
                bulQuestionDelete:  false,
                bulEditaCliente: false,

                //fondo
                alturaFondoDegradado: `calc(100% + 290px)`,

                //datos sobre algun cliente que esta por ser modificado
                memoriaCliente: {},


            }
        },
        methods:{
            ...mapActions(["requestClientes","agregarClienteVuex", "editarClienteVuex", "eliminarClienteVuex"]),
            async agregarCliente(data, bul){
                await this.agregarClienteVuex(data)
            },
            async editarCliente(data){
                await this.editarClienteVuex(data)
            },
            async eliminarCliente(data){
                await this.eliminarClienteVuex(data)
            }

        },
        async mounted(){
            await this.requestClientes()
        },
        computed:{
        ...mapState(["token", "server", "clientes", "clientesFiltros"]),
        }
    }
</script>

<style scoped>
    .fondo-degradado-recover{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        background: linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%), linear-gradient(248.49deg, rgba(255, 255, 255, 0) 41.48%, #297F87 100.24%);
        z-index: -10;
    }
    .contenedor-sesiones-cliente{
        width: 100%;
        margin-top:80px;
        height: 100px;
    }
    .space-header{
        height: 80px;
        width: 100%;
    }
    .boton-sesiones{
        width: 15%;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
        font-size: 26px;
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 15px white;
        padding-top:15px ;
        padding-bottom:15px ;
        border-radius: 16px;
        cursor: pointer;
        border-bottom:0px solid #110133;
        transition: 0.2s ease-in-out;
    }
    .contenedor-cliente{
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
    }
    .entrada,.salida{
        width: 40%;
    }
    .texto-check{
        margin-top: 6px;
    }
    .contenedor-botones{
        margin-top:50px;
        width: 100%;
    }
    .button-exportar{
        height: 40px;
        border:none;
        border-radius:10px;
        background: #297F87;
        color: white;
        text-align: center;
        font-family: Rubik;
        font-size:20px ;
        width: 30%;
        margin-left: 0%;
    }
    .contenedor-caja-historial{
        position:relative;
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        border-radius: 16px;
        padding: 20px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 15px white;
        width: 90%;
        margin-left:5%;
        transition: all 1s;
        backdrop-filter: blur(2px);
        margin-top: 20px;
        display: flex;
        height: auto;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 50px;
        margin-bottom: 10px;
    }    
    .caja-filtro{
        background:rgba(196,196,196,0.25);
        width: 100px;
        height: 30px;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
        font-size: 15px;
        position: absolute;
        left: 20px;
        top: 20px;
        cursor:pointer;
        transition: all 1s;
    }
    .caja-filtros{
        width: calc(100% - 20px);
        background:rgba(196,196,196,0.25);
        height: 100px;
        margin-top:40px;
        border-radius:0px 10px 10px 10px;
        transition: all 1s;
        overflow: hidden;
    }
    .simboloIzquierda{
        height: 1.5px;
        width: 7.5px;
        background: black;
        margin-left: 7.5px;
        transition: all 1s;
    }
    .simboloDerecha{
        height: 1.5px;
        width: 7.5px;
        background: black;
        margin-left: -3.5px;
        transition: all 1s;
    }
    .margin-izquierda{
        margin-left:4%;
    }
    .input,.input2,.input3{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 100%;
        padding-left: 10px;
        padding-top: 2px;
        padding-bottom: 3px;
        height: 30px;
        background: white;
        margin-left:0%;
        margin-bottom:25px;
        font-size:16px ;
    }
    .caja-enunciado{
        width: calc(100% - 20px);
        background:rgba(196,196,196,0.25);
        height: 50px;
        border-radius:10px;
        transition: all 1s;
        margin-top:15px;
    }
    .checkbox,.checkbox2{
        width: 20px;
        height: 20px;
        border-radius:50%;
        border: #6464646b 2px solid;
        margin-right: 15px;
        margin-top:-8px;
        background: white;
    }
    .caja,.caja2{
        width: 12px;
        height: 12px;
        background: #297F87;
        border-radius:2px;
        opacity: 1;
        border-radius:50%;
    }
</style>